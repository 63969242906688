@import './themes';

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }

  nb-card {
    max-width: 90vw;
  }
  [nbInput]:read-only,
  [nbInput]:-moz-read-only,
  input[readonly],
  [readonly] {
    background-color: white;
    border: none;
    border-bottom: 1px solid lightgray;
    padding: 0;
  }
  nb-auth nb-card-header {
    display: none !important;
  }
  nb-auth nb-layout-column {
    padding: 0 !important;
  }
  nb-auth nb-auth nb-card-header {
    display: none !important;
  }
  nb-auth nb-card {
    max-width: 100%;
  }
  ngx-colors .circle {
    height: 50px !important;
    width: 50px !important;
  }

  nb-card .icon.status-success, .percentage-box {
    background-image: inherit !important;
    background-color: var(--badge-success-background-color) !important;
  }


  .ng2-smart-title ng2-smart-table-title > a {
    color: var(--card-header-text-color) !important;
    text-decoration-color: var(--card-header-text-color) !important;
  }


  ngx-colors-panel button:last-of-type {
    background-color: var(--button-filled-danger-background-color) !important;
    border-color: var(--button-filled-danger-border-color) !important;
    color: var(--button-filled-danger-text-color) !important;
    margin-right: 10px;
  }

  ngx-colors-panel button:first-of-type {
    background-color: var(--button-filled-primary-background-color) !important;
    border-color: var(--button-filled-primary-border-color) !important;
    color: var(--button-filled-primary-text-color) !important;
    margin-right: 10px;
  }

  nb-stepper .header  {
    display: none !important;
  }

  nb-stepper .header .step.selected {
    display: flex     !important;
  }
  .rounded-card {
    border-radius: 10px !important;
  }

}
