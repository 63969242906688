@mixin ngx-layout() {
  @include media-breakpoint-down(is) {
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
  input[readonly],
  [readonly] {
    background-color: white;
    border: none;
    border-bottom: 1px solid lightgray;
    padding: 0;
  }
}
